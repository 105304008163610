import { Star } from 'lucide-react';

interface StarRatingProps {
  rating: number;
  onChange?: (rating: number) => void;
  readonly?: boolean;
}

export default function StarRating({ rating, onChange, readonly = false }: StarRatingProps) {
  const handleClick = (index: number, half: boolean) => {
    if (readonly || !onChange) return;
    const newRating = half ? index + 0.5 : index + 1;
    onChange(newRating);
  };

  return (
    <div className="flex gap-1">
            {[...Array(5)].map((_, index) => {
                const fillPercentage = Math.max(0, Math.min(100, (rating - index) * 100)); // Pourcentage de remplissage

                return (
                    <div
                        key={index}
                        className={`relative ${!readonly ? 'cursor-pointer' : ''}`}
                        onClick={readonly ? undefined : () => handleClick(index, false)}
                    >
                        {!readonly && (
                            <div
                                className="absolute left-0 w-1/2 h-full"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClick(index, true);
                                }}
                            />
                        )}
                        <Star
                            className={`w-6 h-6 text-gray-600`}
                        />
                         <Star
                            className={`w-6 h-6 absolute inset-0 text-gold-400 fill-current`}
                            style={{ clipPath: `inset(0 ${100 - fillPercentage}% 0 0)` }}
                        />
                    </div>
                );
            })}
        </div>
  );
}